<template lang="pug">
q-tooltip(
    content-class="delivery-tooltip"
    anchor="top middle"
    self="center middle"
    :hide-delay="300000"
    :offset="offset")
    | {{ title }}
    q-icon.q-ml-xs.q-mb-xs(
        v-if="icon"
        size="16px"
        name="etabl:ruble"
        color="positive"
    )
</template>

<script>
export default {
    name: 'BaseMobileTooltipe',
    props: {
        title: {
            type: String,
        },
        icon: {
            type: Boolean,
            default: () => false,
        },
        offset: {
            type: Array,
            default: () => [0, 20],
        },
    },
};
</script>
