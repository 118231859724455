<template lang="pug">
router-link.preview-mobile.column.no-wrap.relative-position(
    v-if="isVertical"
    flat
    :class="isCard ? '' : 'q-mr-md'"
    :style="isCard ? 'width: 100%;' : 'width: 166px; min-height: 294px'"
    :title="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`"
    :to="city ? `/${city.slug}/product/${data.url}` : `/product/${data.url}`")
    .absolute-top-right.row.items-center.justify-center.q-pa-sm.preview-mobile__icons
        .flex.column.items-end
            q-icon.q-mt-xs.q-mr-xs(
                name="etabl:heart"
                size="20px"
                :color="isFavourite ? 'negative' : 'grey-7'"
                @click.prevent.stop="updateFavourite"
            )
            //q-icon.q-mt-xs.q-mr-xs(
            //    v-if="data.isMarker"
            //    name="etabl:side-sale"
            //    size="22px"
            //    color="negative")
            .b-radius-16.border-negative.font-12.text-negative.text-weight-medium.q-px-xs.q-mt-sm(v-if="data.discount && recommendationId")
                | {{ data.discount }}
    .absolute-top-left.justify-center.relative-position.row.q-pa-sm.preview-mobile__icons
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.isPrescription && !data.isErp"
            name="img:rx.svg"
            size="20px"
            color="negative"
            @click.prevent.stop="")
            base-mobile-tooltip(title="По рецепту")
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.isPrescription && data.isErp"
            name="img:rx_e.svg"
            size="20px"
            color="negative"
            @click.prevent.stop="")
            base-mobile-tooltip(title="По электронному рецепту")
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.cold"
            name="etabl:frost"
            size="20px"
            color="light-blue"
            @click.prevent.stop="")
            base-mobile-tooltip(title="Особые условия хранения")
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.truCode"
            name="img:fss.svg"
            size="20px"
            @click.prevent.stop="")
            base-mobile-tooltip(:title="fssTitle")
    .q-mt-sm.row.items-center.justify-center.full-width.relative-position.preview-mobile__img
        .absolute-bottom-left.justify-center.q-ml-xs.cold-recipe(
            v-if="data.discount300 || data.discount800 || (data.isMarker && !notAvailable && data.priceCrossed)"
            style="bottom: -4px"
        )
            .q-px-sm.text-white.font-12.text-weight-regular.b-radius-16(
                style="padding-top: 2px; padding-bottom: 2px"
                :class="sliderProductDay || (data?.advertismentTitles?.length && data?.advertismentTitles[0]) === 'Товар дня' ? 'bg-blue-10' : 'bg-negative'"
            )
                div(v-if="data?.titleShort") {{data?.titleShort}}
                div(v-else-if="data?.advertismentTitles?.length" ) {{data?.advertismentTitles[0]}}
                div(v-else ) Акция
                //base-tooltip(title="Акция действительна только в розничной сети аптек «Табле.Точка»" :maxWidth="'250px'")
        img.full-height(
            ref="slideImg"
            style="max-width: 90px;max-height: 70px"
            :src="img"
            :title="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`"
            :alt="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`"
            loading='lazy'
        )
    .column.q-mt-xs.q-pb-sm.justify-between
        .column.col-grow.full-width.q-px-sm.no-wrap
            .column.text-ellipsis(:style="[notAvailable ? 'padding-top:14px; min-height: 195px' :'padding-top:14px; min-height: 166px'] ")
                .font-14.text-weight-regular.line-height-22.text-primary()
                    span.block#name(style="white-space: pre-line;") {{formattedName(`${data.name} ${data.subtitleShort}`)}}
                        base-tooltip(:title="data.name")
                //.font-14.text-positive.text-weight-medium.preview-mobile__title {{ data.name }}
                //.font-12.text-primary.preview-mobile__sub-title {{ data.subtitleShort }}
                //.font-12.text-grey-12.q-pt-xs.preview-mobile__sub-title  {{ data.manufacture }}
                ////.font-12.text-grey-12.text-capitalize.preview-mobile__sub-title  {{ data.country }}
                //.font-12.text-primary(v-if="data.mnn") {{ data.mnn[0] }}
                .font-12.text-grey.q-my-sm {{ data.manufacture }}
                .font-12.text-primary
                    span.block#mnn(v-if="data.mnn?.length").q-pb-sm {{formattedName(`${data.mnn[0]}`)}}
                    span.block.q-pb-sm#mnn(v-else) &nbsp
                    span.row.text-grey.q-mb-xs.items-center.font-13.full-width(style="min-height: 39px")
                        span.block(v-if="data.isStock") В наличии
                        span.block(v-if="!data.isStock && data.isPreorder") Под заказ c {{ preorderDate }}
                        span.row.items-center.full-width(v-if="(data.isPreorder || data.qDs) && (!data.isPrescription  || data.isErp)")
                            span.block.text-positive.green-bool.q-mx-xs(v-if="!notAvailable && (data.isPreorder || data.qDs) && (!data.isPrescription  || data.isErp)" )
                            span.block.text-grey-9.q-mx-xs.font-13(v-if="!notAvailable" )
                                | Доставка
                        span.block.text-grey-9.font-13(v-if="notAvailable" )
                            | Нет в наличии
                //.q-mt-xs(v-if="data.isStock || data.isPreorder")
                //    .row.text-grey.q-mt-xs.q-mb-xs.items-center
                //        q-icon(
                //            name="etabl:completed"
                //            color="positive"
                //            size="12px"
                //        ).q-mr-xs
                //        .font-12
                //            | Забрать в аптеке
                    .row.text-grey.items-center.flex.justify-between(v-if="!data.isPrescription || data.isErp")
                        .row
                            q-icon(
                                name="etabl:completed"
                                color="positive"
                                size="12px"
                            ).q-mr-xs
                            .font-12
                                | Доставка курьером
            .q-my-sm(v-if="!notAvailable" style="min-height: 39px")
                .row.items-end.justify-between.full-height.full-width.no-wrap
                    q-icon(
                        v-if="freeDelivery && (data.qDs || data.isPreorder) && (!data.isPrescription || data.isErp)"
                        :name="data.delivery == 1 ? 'etabl:delivery_one' : 'etabl:delivery_two'"
                        size="20px"
                        :color="data.delivery == 1 ? 'positive' : 'deep-purple-6'"
                        @click.prevent.stop="")
                        base-mobile-tooltip(:title="data.delivery == 1 ? 'Бесплатная доставка' : 'Бесплатная доставка при заказе от 2 товаров'")

                    .float-right.text-positive.text-weight-medium.q-ma-none.justify-end.items-center.full-height.full-width(v-if="!notAvailable")
                        .row.items-end.justify-end.no-wrap(v-if="data.isMarker && salePercent > 0")
                            .sale.q-mr-sm.b-radius-16.border-negative.flex.items-center(style="padding: 2px 4px")
                                .font-12.line-height-16.text-negative
                                    | {{ salePercent }}%
                            .row.items-end.justify-end.column
                                .text-left.font-12.text-grey(
                                    v-if="data.priceOld"
                                    style="text-decoration: line-through;"
                                )
                                    span(v-if="!userAddress?.address_id && data.priceCrossed !== data.price" ) от&nbsp;
                                    | {{ new Intl.NumberFormat('ru-RU').format(data.priceCrossed) }}
                                    span &nbsp;₽
                                .text-left.text-weight-bold
                                    span(v-if="!userAddress?.address_id" ) от&nbsp;
                                    | {{ new Intl.NumberFormat('ru-RU').format(data.price) }}
                                    span &nbsp;₽
                        .row.justify-end.full-height.items-end.full-width(v-else)
                            .text-left.text-weight-bold(v-if="!!data.price")
                                | от {{ new Intl.NumberFormat('ru-RU').format(data.price) }}
                                span &nbsp;₽
        .q-px-sm.relative-position(
            v-if="!notAvailable"
            style="height: 33px")
            base-btn(
                v-if="data.limit === 0 || data.pku"
                color="accent"
                text_color="positive"
                font_size="12"
                height="33"
                label="Только в аптеке"
                background="postivie"
                loading_color="secondary"
                :loading="loading"
                disable)
            template(v-else)
                base-btn(
                    v-if="!inCart"
                    height="33"
                    :label="recommendationId ? 'Заменить' : 'В корзину'"
                    bradius="16"
                    background="postivie"
                    text_color="secondary"
                    loading_color="secondary"
                    :loading="loading"
                    :disable="loading"
                    @click.prevent.stop="addToCart")
                .absolute.full-height.row.justify-between.preview-mobile__counter(v-if="inCart")
                    q-btn.col-4.justify-center.items-center.preview-mobile__button-counter(
                        unelevated
                        @click.prevent.stop="setAmount(0)") &ndash;
                    .row.col-4.justify-center.items-center.bg-secondary {{ amount }}
                    q-btn.col-4.justify-center.items-center.preview-mobile__button-counter(
                        v-if="!preventAmoutUp"
                        unelevated
                        @click.prevent.stop="setAmount(1)") +
                    q-btn.col-4.justify-center.items-center.preview-mobile__button-disable(
                        v-else
                        unelevated
                        @click.prevent.stop="limit()") +
        .row.flex.justify-center.items-center.q-mx-sm(v-else)
            //.font-12.text-weight-medium.text-grey-8
            //    | Нет в наличии
            .row.items-center.justify-end(
                @click.prevent.stop="subscribe ? unSubscribeProduct() : subscribeProduct()"
            )
                q-icon(
                    name="etabl:notification"
                    size="24px"
                    :color="subscribe ? 'positive' : 'grey-7'").q-pr-sm.q-pl-xs
                .font-12(:class="{'text-positive': subscribe, 'text-grey-9': !subscribe}")
                    | {{ subscribe ? 'Отписаться' : 'Подписаться'}}

            base-btn(
                v-if="notAvailable && data.analogs"
                label="Подобрать аналог"
                color="secondary"
                text_color="positive"
                height="34"
                loading_color="secondary"
                :loading="loading"
                :disable="loading"
                bradius="16"
                border="1px solid #0CA551"
                font_size="10"
                @click.prevent.stop="showAnalogs").q-mt-sm
            base-btn(
                v-if="notAvailable && !data.analogs"
                height="33"
                :label="'Нет в наличии'"
                bradius="16"
                background="bg-grey-9"

                text_color="white"
                loading_color="secondary"
                :loading="loading"
                :disable="loading"
                @click.prevent.stop="").q-mt-sm
//Horizontal
router-link.preview-mobile.preview-mobile-horizontal.row.no-wrap.relative-position(
    v-else
    flat
    :title="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`"
    :to="city ? `/${city.slug}/product/${data.url}` : `/product/${data.url}`")
    .absolute-top-left.justify-center.relative-position.row.q-pa-sm.preview-mobile__icons
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.isPrescription && !data.isErp"
            name="img:rx.svg"
            size="20px"
            color="negative"
            @click.prevent.stop="")
            base-mobile-tooltip(title="По рецепту")
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.isPrescription && data.isErp"
            name="img:rx_e.svg"
            size="20px"
            color="negative"
            @click.prevent.stop="")
            base-mobile-tooltip(title="По электронному рецепту")
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.cold"
            name="etabl:frost"
            size="20px"
            color="light-blue"
            @click.prevent.stop="")
            base-mobile-tooltip(title="Особые условия хранения")
        q-icon.q-mt-xs.q-ml-xs(
            v-if="data.truCode"
            name="img:fss.svg"
            size="20px"
            @click.prevent.stop="")
            base-mobile-tooltip(:title="fssTitle")
    .full-width.row.q-pt-md.q-px-md.q-pb-md
        //.absolute-top-right.justify-center.q-ma-sm.relative-position.cold-recipe(v-if="data.discount300 || data.discount800")
        //    .q-px-sm.q-py-xs.text-white.bg-negative.font-12.text-weight-regular.b-radius-16
        //        div(v-if="data?.titleShort") {{data?.titleShort}}
        //        div(v-else ) Скидка
        //        base-tooltip(title="Акция действительна только в розничной сети аптек «Табле.Точка»" :maxWidth="'250px'")
        .col-3.full-height.row.items-center.justify-center.preview-mobile-horizontal__img
            q-img(
                width="100%"
                fit="contain"
                ref="slideImg"
                :src="img"
                :title="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`"
                :alt="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`"
                style="object-fit: contain"
                spinner-color="positive"
                loading="lazy"
            )
        .col-9.q-pl-md
            .column.col-grow.full-width.q-px-sm.justify-between.no-wrap.full-height.relative-position
                //.absolute-top-right.justify-center.q-ma-xs.relative-position.cold-recipe(v-if="data.isMarker && !notAvailable" )
                //    .q-px-sm.q-py-xs.text-white.bg-negative.font-12.text-weight-regular.b-radius-16
                //        div(v-if="data?.titleShort") {{data?.titleShort}}
                //        div(v-else ) Скидка
                .column.text-ellipsis(:class="[{'q-mb-lg q-pb-sm': !data.isMarker || notAvailable}]")
                    .q-px-sm.q-py-xs.text-white.font-12.text-weight-regular.b-radius-16(
                        v-if="data.isMarker && !notAvailable && data.priceCrossed"
                        :class="sliderProductDay || (data?.advertismentTitles?.length && data?.advertismentTitles[0]) === 'Товар дня' ? 'bg-blue-10' : 'bg-negative'"
                        style="width: fit-content; margin-bottom: 6px"
                    )
                        div(v-if="data?.titleShort") {{data?.titleShort}}
                        div(v-else-if="data?.advertismentTitles?.length" ) {{data?.advertismentTitles[0]}}
                        div(v-else ) Акция
                    .font-14.text-weight-regular.line-height-22.text-primary
                        span.block#name(style="white-space: pre-line;") {{formattedName(`${data.name} ${data.subtitleShort}`)}}
                            base-tooltip(:title="data.name")

                    //.font-14.text-positive.text-weight-medium.preview-mobile__title {{ data.name }}
                    //.font-12.text-primary.preview-mobile__sub-title {{ data.subtitleShort }}

                    .font-12.text-grey.q-my-sm {{ data.manufacture }}
                    .font-12.text-primary(v-if="data.mnn")
                        span.block#mnn(v-if="data.mnn?.length").q-pb-sm {{formattedName(`${data.mnn[0]}`)}}
                        //span.block.q-pb-sm#mnn(v-else) &nbsp
                    span.row.text-grey.q-mb-xs.items-center.font-13.full-width
                        span(v-if="data.isStock") В наличии
                        span(v-if="!data.isStock && data.isPreorder") Под заказ c {{ preorderDate }}
                        span.row.items-center.no-wrap(v-if="(data.isPreorder || data.qDs) && (!data.isPrescription  || data.isErp)")
                            span.block.text-positive.green-bool.q-mx-xs(v-if="!notAvailable && (data.isPreorder || data.qDs) && (!data.isPrescription  || data.isErp)" )
                            span.block.text-grey-9.q-mx-xs.font-13(v-if="!notAvailable" )
                                | Доставка
                        span.text-grey-9.q-mx-sm.font-13(v-if="notAvailable" )
                            | Нет в наличии
                    //.q-mt-sm(v-if="data.isStock || data.isPreorder")
                    //    .row.text-grey.q-mt-xs.q-mb-xs.items-center
                    //        q-icon(
                    //            name="etabl:completed"
                    //            color="positive"
                    //            size="12px"
                    //        ).q-mr-xs
                    //        .font-12
                    //            | Забрать в аптеке
                    //    .row.text-grey.items-center.flex.justify-between(v-if="!data.isPrescription || data.isErp")
                    //        .row
                    //            q-icon(
                    //                name="etabl:completed"
                    //                color="positive"
                    //                size="12px"
                    //            ).q-mr-xs
                    //            .font-12
                    //                | Доставка курьером
                div
                    .row.items-end.q-pb-sm(:class="freeDelivery && (data.qDs || data.isPreorder) && (!data.isPrescription || data.isErp) ? 'justify-between' : 'justify-end'")
                        q-icon.q-pl-sm(
                            v-if="freeDelivery && (data.qDs || data.isPreorder) && (!data.isPrescription || data.isErp)"
                            :name="data.delivery == 1 ? 'etabl:delivery_one' : 'etabl:delivery_two'"
                            size="20px"
                            :color="data.delivery == 1 ? 'positive' : 'deep-purple-6'"
                            @click.prevent.stop="")
                            base-mobile-tooltip(:title="data.delivery == 1 ? 'Бесплатная доставка' : 'Бесплатная доставка при заказе от 2 товаров'")
                        .text-positive.text-weight-medium.q-ma-none.justify-end.items-center.q-mb-x(v-if="!notAvailable")
                            .row.items-end.justify-center(v-if="data.isMarker && salePercent > 0")
                                .sale.q-mr-sm.b-radius-16.border-negative.flex.items-center(style="padding: 2px 4px")
                                    .font-12.line-height-16.text-negative
                                        | {{ salePercent }}%
                                .row.items-end.justify-end.column
                                    .text-left.font-12.text-grey(
                                        v-if="data.priceOld"
                                        style="text-decoration: line-through;"
                                    )
                                        span(v-if="!userAddress?.address_id && data.priceCrossed !== data.price" ) от&nbsp;
                                        | {{ new Intl.NumberFormat('ru-RU').format(data.priceCrossed) }}
                                        span &nbsp;₽
                                    .text-left.text-weight-bold
                                        span(v-if="!userAddress?.address_id" ) от&nbsp;
                                        | {{ new Intl.NumberFormat('ru-RU').format(data.price) }}
                                        span &nbsp;₽
                            .row.justify-end(v-else)
                                .text-left.text-weight-bold(v-if="!!data.price" )
                                    | от {{ new Intl.NumberFormat('ru-RU').format(data.price) }}
                                    span &nbsp;₽


                    .items-center.q-mb-sm(v-if="notAvailable")
                        //.text-grey-9.line-height-20.flex.justify-end.q-pb-xs(v-if="data.analogs")
                        //    | Нет в наличии
                        .row.items-center.justify-end(
                            @click.prevent.stop="subscribe ? unSubscribeProduct() : subscribeProduct()"
                        )
                            q-icon(
                                name="etabl:notification"
                                size="24px"
                                :color="subscribe ? 'positive' : 'grey-7'").q-pr-sm.q-pl-xs
                            .font-12(:class="{'text-positive': subscribe, 'text-grey-9': !subscribe}")
                                | {{ subscribe ? 'Отписаться' : 'Подписаться'}}
                    .row(
                        :class="!notAvailable || data.analogs ? 'justify-between' : 'justify-end'"
                    )
                        .row.items-center.preview-mobile__icons(
                            @click.prevent.stop="updateFavourite")
                            q-icon.q-pl-sm(
                                name="etabl:heart"
                                size="xs"
                                :color="isFavourite ? 'negative' : 'grey-7'")
                        .relative-position(
                            v-if="notAvailable && data.analogs"
                            style="height: 34px"
                        )
                            base-btn.btn-bottom(
                                label="Подбор аналога"
                                color="secondary"
                                text_color="positive"
                                height="34"
                                loading_color="secondary"
                                :loading="loading"
                                :disable="loading"
                                width="150"
                                bradius="16"
                                border="1px solid #0CA551"
                                font_size="10"
                                @click.prevent.stop="showAnalogs")
                        .row.items-center.justify-end(v-if="notAvailable && !data.analogs")
                            //.text-grey-9.line-height-20.q-mr-md
                            //    | Нет в наличии
                        .relative-position(
                            v-if="!notAvailable"
                            style="height: 34px")
                            base-btn(
                                v-if="data.limit === 0 || data.pku"
                                color="accent"
                                text_color="positive"
                                font_size="12"
                                height="34"
                                width="150"
                                bradius="16"
                                label="Только в аптеке"
                                background="postivie"
                                loading_color="secondary"
                                :loading="loading"
                                disable)
                            template(v-else)
                                base-btn.btn-bottom(
                                    v-if="!inCart"
                                    height="34"
                                    width="150"
                                    bradius="16"
                                    label="В корзину"
                                    background="postivie"
                                    text_color="secondary"
                                    loading_color="secondary"
                                    :loading="loading"
                                    :disable="loading"
                                    @click.prevent.stop="addToCart")
                                .absolute.row.justify-between.preview-mobile__counter.preview-mobile-horizontal__counter(v-if="inCart")
                                    q-btn.col-4.justify-center.items-center.preview-mobile__button-counter(
                                        :disable="!!recommendationId && !!inCart && amount === 1"
                                        unelevated
                                        @click.prevent.stop="setAmount(0)") &ndash;
                                    .row.col-4.justify-center.items-center.bg-secondary {{ amount }}
                                    q-btn.col-4.justify-center.items-center.preview-mobile__button-counter(
                                        v-if="!preventAmoutUp"
                                        unelevated
                                        @click.prevent.stop="setAmount(1)") +
                                    q-btn.col-4.justify-center.items-center.preview-mobile__button-disable(
                                        v-else
                                        unelevated
                                        @click.prevent.stop="limit()") +
</template>

<script>
import { useQuasar } from 'quasar';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import BaseBtn from 'components/Base/BaseBtn.vue';
import BaseMobileTooltip from 'components/Base/BaseMobileTooltip.vue';
import BaseSpiner from 'components/Base/BaseSpiner.vue';
import BaseTooltip from 'components/Base/BaseTooltip.vue';


export default {
    name: 'PreviewMobile',
    components: {
        BaseBtn,
        BaseMobileTooltip,
        BaseSpiner,
        BaseTooltip,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        subtitle: {
            type: Boolean,
            default: () => true,
        },
        manufacture: {
            type: Boolean,
            default: () => true,
        },
        country: {
            type: Boolean,
            default: () => false,
        },
        count: {
            type: Boolean,
            default: () => true,
        },
        countSlides: {
            type: Number,
        },
        index: {
            type: Number,
        },
        countVisibleSlides: {
            type: Number,
        },
        styles: {
            type: String,
        },
        isCard: {
            type: Boolean,
            default: () => false,
        },
        isVertical: {
            type: Boolean,
            default: () => true,
        },
        favPage: {
            type: Boolean,
            default: () => false,
        },
        basket: {
            type: Boolean,
            default: () => false,
        },
        variant: {
            type: String,
        },
        pickupVariant: {
            type: String,
        },
        recommendationId: {
            type: String,
        },
        marketing: {
            type: Boolean,
            default: () => false,
        },
        sliderProductDay: {
            type: Boolean,
            default: () => false,
        },
    },
    emits: ['sliderReady'],
    setup(props, { emit }) {
        const $q = useQuasar();
        const store = useStore();
        const router = useRouter();
        const amount = ref(1);
        const loading = ref(false);
        const tooltipe = ref(true);
        const amountTimer = ref(null);
        const imgLink = ref(null);
        const slide = ref(null);
        const slideImg = ref(null);
        const subscribe = ref(true);
        const isLocalFavourite = ref(false);

        const isActivAccessibility = computed(() => store.getters['styles/isActiv']);

        const userAddress = computed(() => store.getters['user/USER_ADDRESS']);

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const userAuthorized = computed(() => store.getters['user/USER_AUTHORIZED']);
        const isFavourite = computed(() => {
            if (props.favPage) {
                return true;
            }
            if (userAuthorized.value) {
                return isLocalFavourite.value;
            } else {
                const favourites = store.getters['localStorage/FAVOURITES'];
                return favourites?.find((item) => item === props.data.id);
            }
        });

        const cartProducts = computed(() => store.getters['localStorage/BASKET_PRODUCTS']);

        const notAvailable = computed(() => {
            return !!(!props.data.priceNew && !props.data.priceOld && !props.data.minPrice)
                || !!(!props.data.maxPreorderCount && !props.data.maxStockCount);
        });

        // const salePercent = computed(() => {
        //     return Math.ceil((1 - (props.data.priceNew / props.data.priceOld)) * 100);
        // });

        // const salePercent = computed(() => {
        //     return Math.ceil(((props.data.priceCrossed - props.data.price) / props.data.priceCrossed) * 100);
        // });

        const salePercent = computed(() => {
            if (props.data.priceCrossed) {
                const percent = ((props.data.priceCrossed - props.data.price) / props.data.priceCrossed) * 100;
                const decimalPart = Math.floor((percent % 1) * 10);

                if (decimalPart > 5) {
                    return `${Math.ceil(percent)}`;
                } else {
                    return `${Math.floor(percent)}`;
                }
            } else {
                return false;
            }
        });

        const priceFrom = computed(() => {
            if (props.data.maxStockCount && !props.data.maxPreorderCount) {
                return props.data.minPrice;
            }

            return false;
        });

        const inCart = ref(false);

        const img = computed(() => props.data.previewImage);

        const letsWarning = () => {
            $q.notify({
                color: 'warning',
                message: 'Произошла ошибка! Повторите позднее',
            });
        };

        const updateFavourite = async () => {
            window?.TapticEngine?.unofficial.weakBoom();
            if (isFavourite.value) {
                userAuthorized.value
                    ? deleteProductFromUserFav()
                    : store.commit('localStorage/DELETE_FAVOURITE_PRODUCT', props.data.id);
            } else {
                userAuthorized.value
                    ? addProductToUserFav()
                    : store.commit('localStorage/ADD_FAVOURITE_PRODUCT', props.data.id);
            }
        };

        const deleteProductFromUserFav = async () => {
            try {
                await store.dispatch('user/DELETE_FAVOURITE_PRODUCT', props.data.id);
                await store.commit('user/DELETE_USER_FAVOURITE_PRODUCT', props.data.id);
                isLocalFavourite.value = false;
            } catch (e) {
                isLocalFavourite.value = true;
            }
        };

        const addProductToUserFav = async () => {
            try {
                await store.dispatch('user/ADD_FAVOURITE_PRODUCT', props.data.id);
                isLocalFavourite.value = true;
            } catch (e) {
                isLocalFavourite.value = false;
            }
        };

        const goToCart = () => {
            router.push('/basket');
        };

        const addToCart = async () => {
            window?.TapticEngine?.unofficial.weakBoom();
            userAuthorized.value
                ? await putProductInCart()
                : await putProductInLocalCart();

            try {
                ym(72234883, 'reachGoal', 'klik-korzina');

                window.dataLayer.push({
                    'ecommerce': {
                        'currencyCode': 'RUB',
                        'add': {
                            'products': [
                                {
                                    'id': props.data.id,
                                    'name': props.data.name + props.data.subtitleShort,
                                    'brand': props.data.manufacture,
                                    'price': props.data.price,
                                },
                            ],
                        },
                    },
                });
            } catch {
            }

            if (props.marketing) {
                try {
                    ym(72234883, 'reachGoal', 'action_product_add');
                } catch {
                }
            }
        };

        // добавление товара в корзину для авторизоаванных
        const putProductInCart = async () => {
            loading.value = true;
            const in_basket = false;

            if (amount.value && amount.value > 0) {
                try {
                    await store.dispatch('basketNew/PUT_PRODUCT_IN_BUSKET', {
                        product: {
                            id: props.data.id,
                            amount: amount.value,
                            checked: true,
                        },
                        in_basket,
                    });

                    if (props.basket && props.variant == 'pickup') {
                        await store.dispatch('basketNew/GET_PICKUP_BASKET', props.pickupVariant);
                    }
                    if (props.basket && props.variant == 'courier') {
                        await store.dispatch('basketNew/GET_DELIVERY_BASKET', props.pickupVariant);
                    }
                } catch (error) {
                    $q.notify({
                        color: 'negative',
                        message: 'Произошла ошибка. Повторите попытку позже',
                    });
                }
                ;
            }

            if (amount.value == 1 && props.recommendationId && !inCart.value) {
                emit('cleanRecom');
                await store.dispatch('basketNew/CHANGE_BASKET_PRODUCT_AMOUNT', {
                    id: props.recommendationId,
                    count: 0,
                    variant: props.variant,
                    date: props.pickupVariant,
                });
            }

            inCart.value = true;

            loading.value = false;
        };

        const putProductInLocalCart = async (id = props.data.url) => {
            loading.value = true;

            const payload = await store.dispatch('products/GET_PRODUCT_BY_SLUG', { id });
            store.commit('localStorage/ADD_TO_CART', payload);

            inCart.value = true;

            loading.value = false;
        };

        // удаление товара из корзины для авторизоаванных
        const delProductInCart = async () => {
            const in_basket = false;

            try {
                await store.dispatch('basketNew/DELETE_PRODUCT_FROM_BASKET', { id: props.data.id });

                inCart.value = false;

                try {
                    window.dataLayer.push({
                        'ecommerce': {
                            'currencyCode': 'RUB',
                            'remove': {
                                'products': [
                                    {
                                        'id': props.data.id,
                                        'name': props.data.name + props.data.subtitleShort,
                                        'brand': props.data.manufacture,
                                        'price': props.data.price,
                                    },
                                ],
                            },
                        },
                    });
                } catch {
                }
            } catch (error) {
                $q.notify({
                    color: 'negative',
                    message: error.message,
                });
            }
            ;
        };

        const setAmount = async (pm) => {
            if (pm) {
                amount.value++;
            } else {
                if (!(amount.value - 1) || amount.value - 1 < 0) {
                    userAuthorized.value
                        ? await delProductInCart()
                        : store.commit('localStorage/DELETE_ITEMS', props.data.id);
                    amount.value = 1;
                    inCart.value = false;
                    return;
                } else {
                    amount.value--;
                }
                ;
            }
            ;

            if (userAuthorized.value) {
                /**
                 * Если пользователь авторизован
                 * ставим задержку на отправку данных о количестве товара в корзине в 1 сек.
                 */

                if (amountTimer.value) {
                    clearTimeout(amountTimer.value);
                }
                amountTimer.value = setTimeout(await putProductInCart, 300);
            } else {
                // если пользователь НЕ авторизован
                // сохраняем кол-во товара локально в браузере
                store.commit('localStorage/SET_AMOUNT', {
                    id: props.data.id,
                    amount: amount.value,
                });
            }
        };

        const preventAmoutUp = computed(() => {
            if (props.data.limit < 0) {
                return amount.value >= props.data.total_count;
            } else if (props.data.limit == 0 || props.data.pku) {
                return;
            } else if (props.data.limit > 0) {
                return amount.value >= props.data.limit;
            }
            return false;
        });

        const freeDelivery = computed(() => {
            return props.data.delivery && !props.data.cold;
        });

        const delivery = computed(() => {
            return props.data.isStock && !props.data.cold;
        });

        const limit = () => {
            $q.notify({
                color: 'positive',
                message: 'Внимание! Количество ограничено',
            });
        };

        const userEmail = computed(() => store.getters['user/USER_EMAIL']);

        const userSubscribtions = computed(() => store.getters['user/USER_SUBSCRIPTIONS']);

        const isSubscribe = computed(() => {
            const subscribtions = userSubscribtions.value;
            return subscribtions?.find((item) => item === props.data.id);
        });

        const subscribeProduct = async () => {
            if (!userAuthorized.value) {
                showAuth();
            } else {
                if (!userEmail.value) {
                    $q.notify({
                        color: 'negative',
                        message: 'Для получения уведомлений укажите e-mail',
                    });
                } else {
                    try {
                        await store.dispatch('user/ADD_SUBSCRIPTION', props.data.id);
                        subscribe.value = true;
                        $q.notify({
                            color: 'positive',
                            message: 'Ожидайте уведомление на электронную почту',
                        });
                        try {
                            ym(72234883, 'reachGoal', 'klik-postuplenie');
                        } catch {
                        }
                    } catch (error) {
                        $q.notify({
                            color: 'negative',
                            message: 'Произошла ошибка. Повторите попытку позже',
                        });
                    }
                    ;
                }
            }
        };

        const unSubscribeProduct = async () => {
            try {
                await store.dispatch('user/DELETE_SUBSCRIPTION', props.data.id);
                subscribe.value = false;
            } catch (error) {
                $q.notify({
                    color: 'negative',
                    message: 'Произошла ошибка. Повторите попытку позже',
                });
            }
            ;
        };

        watch(() => props.data, (value) => {
            if (!isSubscribe.value && notAvailable.value) {
                subscribe.value = false;
            }
        });

        const checkLocalBasket = () => {
            let localProd = cartProducts.value?.find((item) => item.id === props.data.id);

            if (localProd) {
                amount.value = localProd.amount;
                inCart.value = true;
            } else {
                amount.value = 1;
                inCart.value = false;
            }
            ;
        };

        const checkProdInBasket = () => {
            if (props.data.inBasket) {
                amount.value = props.data.inBasket;
                inCart.value = true;
            } else {
                amount.value = 1;
                inCart.value = false;
            }
        };

        const fssTitle = computed(() => {
            return 'Этот товар можно оплатить в аптеках сети партнера "Табле.Точка" с помощью электронного сертификата ФСС, картой «МИР».';
        });

        const showAnalogs = async () => {
            await store.dispatch('products/GET_PRODUCT_ANALOGS', { slug: props.data.url });
            store.commit('products/SET_CURRENT_PRODUCT_ANALOGS', `${props.data.name} ${props.data.subtitleShort}`);
            if (process.env.CLIENT) {
                // window._paq.push(['trackGoal', 6]);
            }
            emit('showAnalogsList');
        };

        const preorderDate = computed(() => {
            let date = new Date(props.data.nearestDate);

            return date.toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
            });
        });
        const formattedName = (text) => {
            if (!text) return '';

            const lines = text.split(' ').reduce((acc, word) => {
                const lastLine = acc[acc.length - 1];
                if (lastLine && lastLine.length + word.length + 1 > 29) {
                    acc.push(word);
                } else {
                    if (lastLine) {
                        acc[acc.length - 1] += ' ' + word;
                    } else {
                        acc.push(word);
                    }
                }
                return acc;
            }, []);

            if (lines.length > 2) {
                lines.length = 2;
                lines[1] += '...';
            }

            return lines.join(' ');
        };

        onMounted(() => {
            emit('sliderReady');
            if (!userAuthorized.value) {
                checkLocalBasket();
            } else {
                checkProdInBasket();
            }

            if (props.data.isFavourite)
                isLocalFavourite.value = true;

            if (!isSubscribe.value && notAvailable.value) {
                subscribe.value = false;
            }
        });

        return {
            slide,
            inCart,
            slideImg,
            isFavourite,
            updateFavourite,
            addToCart,
            img,
            notAvailable,
            priceFrom,
            goToCart,
            setAmount,
            amount,
            loading,
            preventAmoutUp,
            limit,
            salePercent,
            delivery,
            freeDelivery,
            userSubscribtions,
            isSubscribe,
            subscribeProduct,
            unSubscribeProduct,
            subscribe,
            userEmail,
            deleteProductFromUserFav,
            isLocalFavourite,
            addProductToUserFav,
            checkLocalBasket,
            checkProdInBasket,
            fssTitle,
            city,
            showAnalogs,
            isActivAccessibility,
            formattedName,
            preorderDate,
            userAddress,
        };
    },
};
</script>

<style lang="scss" scoped>
.preview-mobile {
    //height: 352px;
    overflow: hidden;
    text-decoration: none;
    background: $secondary;
    border-radius: $mobile-border-radius;
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.07);

    .green-bool {
        background: $positive;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        display: block;
    }

    span.block {
        &#name {
            display: block !important;
            line-height: 19px;
            height: 57px;
            overflow: hidden;
            white-space: pre-line;
            max-width: 147px;
            text-overflow: ellipsis;
            position: relative;
        }

        &#mnn {
            display: block !important;
            line-height: 16px;
            max-height: 24px;
            overflow: hidden;
            //white-space: pre-line;
            //max-width: 108px;
            max-width: 184px;
            text-overflow: ellipsis;
            position: relative;
        }
    }

    @media only screen and (max-width: 380px) {
        span.block#name {
            display: block !important;
            line-height: 19px;
            height: 57px;
            overflow: hidden;
            white-space: pre-line;
            max-width: 100px;
            text-overflow: ellipsis;
            position: relative;
        }
        .btn-bottom {
            max-width: 120px;
        }
    }

    & .border-negative {
        border: 1px solid #F13613
    }

    &__title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }

    &__sub-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }

    &__img {
        z-index: 0;
        height: 70px;

        & > img {
            object-fit: contain;
            max-width: 75%;
        }
    }

    &__icons {
        z-index: 1;
    }

    &__button {
        height: 33px;
        padding: 2px;
        border-radius: 12px;
    }

    &__counter {
        width: calc(100% - 16px);
        height: 34px;
        overflow: hidden;
        border-radius: 16px;
        color: $primary;
        top: 0;

        font: {
            size: 12px;
            weight: 500;
        }
    }

    &__button-counter {
        min-height: 100%;
        background-color: $positive;
        color: $secondary;
        text-transform: none;
        border-radius: 0;
        padding: 0 !important;

        font: {
            size: 16px;
            weight: 400;
        }
    }

    &__button-disable {
        min-height: 100%;
        background-color: $accent;
        color: $medium-gray;
        text-transform: none;
        border-radius: 0;
        padding: 0 !important;

        font: {
            size: 16px;
            weight: 400;
        }
    }

    &-horizontal {
        span.block {
            &#name {
                display: block !important;
                line-height: 19px;
                height: 38px;
                overflow: hidden;
                white-space: pre-line;
                max-width: 95%;
                text-overflow: ellipsis;
                position: relative;
            }

            &#mnn {
                display: block !important;
                line-height: 16px;
                max-height: 24px;
                overflow: hidden;
                max-width: 184px;
                text-overflow: ellipsis;
                position: relative;
            }
        }

        //height: 257px;

        &__img {
            z-index: 0;

            & > img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        &__counter {
            right: 0;
            width: 150px;
        }
    }
}

</style>
